import React from "react";
import Header from "../components/header/header.js";

const LeBefane = () => {
  return (
    <>
      <Header
        title="Le Befane Shopping Centre"
        logo="lebefane.png"
        metaDescription="Per Befane Shopping Centre abbiamo migliorato le performance della WiFi e garantito servizi di autenticazione clienti, connessione, sistema di centralino, etc."
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              Committente: Consorzio Centro Commerciale Le Befane
              <br /> Anno: 2020-2025
            </h2>
            <p className="description">
              <br />
              <br />
              Le Befane Shopping Centre, il grande centro commerciale di Rimini,
              tra i più grandi d’Europa, raccoglie al suo interno circa 150
              negozi. Si tratta di un luogo in grado di offrire ristoro per ben
              363 giorni all’anno, un luogo dove potersi riscaldare in inverno e
              rinfrescare in estate, dando pieno spazio allo svago e allo
              shopping. Potrete soffermarvi e fare una sosta in uno dei bar.
              Troverete tanto svago nella sala giochi e non manca il mini club
              per i bimbi. Per gli appassionati di cinema c’è il multisala
              Multiplex, di ben 12 sale e potrete assistere a prime visioni
              internazionali e guardare i film d’autore.
            </p>
            <div className="section" />
          </div>
        </div>
        <div className="columns">
          <div className="column is-7">
            <p className="description">
              Esigenze del cliente
              <br />• Migliorare le performance della WiFi all’interno del
              centro commerciale
              <br />• Risolvere problematiche di collegamento verso l’esterno
              degli uffici della direzione, dell’infopoint e della vigilanza
              <br />• Avere uno strumento di sicurezza per le connessioni ad
              internet
              <br />• Rendere estremamente semplice e veloce la procedura di
              autenticazione per i clienti
              <br />• Aggiornamento del servizio centralino e dei collegamenti
              interni
            </p>
            <div className="section" />
          </div>
        </div>
        <div className="columns">
          <div className="column is-7">
            <p className="description">
              Le nostre soluzioni
              <br />• Fornitura connessione in fibra dedicata simmetrica
              <br />• Captive portal Connect@You per autenticazione dei clienti
              del centro
              <br />• Fornitura firewall
              <br />• Sistema di centralino VoIP
              <br />• Fornitura servizio VoIP e apparati telefonici
              <br />• Servizio di assistenza
            </p>
            <div className="section" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeBefane;
